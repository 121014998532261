h1.ui.header {
  font-size: 2.8rem;
}

h2.ui.header {
  font-size: 2.4rem;
}

h3.ui.header {
  font-size: 1.8rem;
  margin-bottom: 1.4rem;
}

.ui.large.menu {
  font-size: 1.5rem;
}

.ui.menu {
  font-size: 1.4rem;
}

.ui.button {
  margin: 0 0.275rem 0 0;
}

.ui.button, .ui.buttons .button, .ui.buttons .or {
  font-size: 1.4rem;
}

.ui.small.button, .ui.small.buttons .button, .ui.small.buttons .or {
  font-size: 1.3rem;
}

.ui.large.button, .ui.large.buttons .button, .ui.large.buttons .or {
  font-size: 1.5rem;
}

.ui.icon.button, .ui.icon.buttons .button {
  padding: 0.864286rem;
}

.ui.circular.button>.icon {
  width: 1rem;
  height: 0.865rem;
  font-size: 1.1rem;
}

.ui.circular.button>.icon:before {
  width: 1.1rem;
  height: 1.1rem;
  font-size: 1.1rem;

}

.ui.button>.icon:not(.button) {
  height: 0.94;
}

i.icon, i.icons {
  font-size: 1.0rem;
}

.ui.mini.button, .ui.mini.buttons .button, .ui.mini.buttons .or {
  font-size: .78571429rem;
}

.ui.checkbox {
  font-size: 1.4rem;
}

.ui.table {
  font-size: 1.4rem;
}

.ui.small.table {
  font-size: 1.26rem;
}

.ui.large.table {
  font-size: 1.54rem;
}

.ui.vertical.steps .step {
  padding: 1.6rem 2.8rem;
}

.ui.vertical.steps .step:first-child {
  padding: 1.6rem 2.8rem;
}

.ui.step, .ui.steps .step {
  font-size: 1rem;
}

.ui.steps .step .title {
  font-family: Lato,"Helvetica Neue",Arial,Helvetica,sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
}

.ui.steps .step .description {
  font-weight: 400;
  font-size: 1.3rem;
  color: rgba(0,0,0,.87);
}

.ui.mini.step, .ui.mini.steps .step {
  padding: 1.25714rem;
  font-size: 1.25714rem;
}

.ui.steps .step .title {
  font-size: 1.25714rem;
}

.ui.form {
  font-size: 1.4rem;
}

.ui.form .field>label {
  margin: 0 0 0.4rem;
}

.ui.dropdown .menu>.item {
  font-size: 1.4rem;
  padding: 1.1rem 1.6rem!important;
}

.ui.modal>.header {
  display: block;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  background: #fff;
  margin: 0;
  padding: 1.75rem 2.1rem;
  box-shadow: none;
  color: rgba(0,0,0,.85);
  border-bottom: 1px solid rgba(34,36,38,.15);
}

.ui.modal>.header:not(.ui) {
  font-size: 2.0rem;
}

.ui.modal>.content {
    font-size: 1.4rem;
    line-height: 1.4;
    padding: 2.1rem;
}

.ui.modal>.actions {
  padding: 1.4rem 1.4rem;
}

.ui.mini.modal>.header:not(.ui) {
  font-size: 1.82rem;
}

.ui.small.modal>.header:not(.ui) {
  font-size: 1.82rem;
}

.ui.divider {
    margin: 1.4rem 0;
}

.ui.breadcrumb {
  font-size: 1.4rem;
}
