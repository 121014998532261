.segmented-control__item {
  display: table-cell;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.segmented-control__input {
  position: absolute;
  visibility: hidden;
}
.segmented-control__label {
  display: block;
  margin: 0 -1px -1px 0;
  /* -1px margin removes double-thickness borders between items */
  padding: 0.6em 0.25em;
  border: 1px solid #d9d9d9;
  color: #6f706f;
  font: 14px/1.5 sans-serif;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.segmented-control__label__disabled {
  display: block;
  margin: 0 -1px -1px 0;
  /* -1px margin removes double-thickness borders between items */
  padding: 0.6em 0.25em;
  border: 1px solid #d9d9d9;
  color: #c2c2c2;
  font: 14px/1.5 sans-serif;
  text-align: center;
  font-weight: bold;
  cursor: pointer;

  background-color: #f8f8f8;
}

.segmented-control__label:hover {
  background: #ffffff;
  color: #1c8ef9;
}
.segmented-control__input:checked + .segmented-control__label {
  background: #dfebf7;
  color: #1c8ef9;
}
